class AnimatedEntry {
  $els: NodeListOf<Element>

  constructor() {
    this.$els = document.querySelectorAll('.animated-entry')
    this.init()
  }

  init() {
    const observer = new IntersectionObserver(this.onIntersectionChange, {
      threshold: 0.1,
      root: null
    })
    for (let i = 0; i < this.$els.length; i++) {
      observer.observe(this.$els[i])
    }
  }

  onIntersectionChange(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('animate-in')
        observer.unobserve(entry.target)
      }
    })
  }
}
export default AnimatedEntry
