import eventBus from '@/util/event-bus'
import { EVENT } from '@/util/enums'

class SectionObserver {
  observer: IntersectionObserver

  constructor() {
    this.observer = new IntersectionObserver(
      (entries) => SectionObserver.onSectionChange(entries),
      {
        root: null,
        threshold: 0,
      }
    )
    // TODO init after vue components mounted
    setTimeout(() => this.init(), 500)
  }

  private init(): void {
    const sections = document.querySelectorAll('[data-section]')

    for (let i = 0; i < sections.length; i++) {
      this.observer.observe(sections[i])
    }
  }

  private static onSectionChange(
    entries: Array<IntersectionObserverEntry>
  ): void {
    for (let i = 0; i < entries.length; i++) {
      const entry = entries[i]
      const sectionType = entry.target.getAttribute('data-section')
      if (entry.isIntersecting) {
        switch (sectionType) { // onEnterViewport
          case 'stage':
            eventBus.$emit(EVENT.SECTION_STAGE_IN)
            document.documentElement.classList.add('stage-intersecting')
            break
          case 'keyfigures':
            eventBus.$emit(EVENT.SECTION_KEYFIGURES_IN, entry)
            break
          case 'separator':
            eventBus.$emit(EVENT.SECTION_SEPARATOR_IN)
            break
          case 'img-zoom':
            // eventBus.$emit(EVENT.SECTION_IMAGEZOOM_IN)
            break
          default:
            eventBus.$emit(EVENT.SECTION_IN, entry)
        }
      } else if (!entry.isIntersecting) { // onExitViewport
        switch (sectionType) {
          case 'stage':
            eventBus.$emit(EVENT.SECTION_STAGE_OUT)
            document.documentElement.classList.remove('stage-intersecting')
            break
          case 'separator':
            eventBus.$emit(EVENT.SECTION_SEPARATOR_OUT, entry)
            break
          case 'img-zoom':
            // eventBus.$emit(EVENT.SECTION_IMAGEZOOM_OUT, entry)
            break
          default:
            eventBus.$emit(EVENT.SECTION_OUT, entry)
        }
      }
    }
  }
}

export default SectionObserver
